import { AppConfig, SearchField } from './types/appcontext-store'
import { Action, ActionType } from './actions/actions'

export const appContextState: AppConfig = {
  tenant: '01WhzaFGtquHieE',
  schema: 'EWDiscover',
  envUrl: '',
  downloadUrl: '',
  searchField: [],
  searchIsCategory: false,
  searchedCategory: '',
  displayCategoryDownloadModal: false,
  dispatch: () => ({}),
  isLoggedIn: false,
  libraryLoginName: 'libraryLogin',
  libraryLoginValue: '1',
}

//#################################
// GET REFERRER FOR LOGIN
// If referrer is frontify autoLogin
const referrerCheck = 'https://frontify.lufthansa.com'
if (
  document.referrer.indexOf(referrerCheck) >= 0 ||
  localStorage.getItem(appContextState.libraryLoginName) === appContextState.libraryLoginValue
) {
  Object.assign(appContextState, { ...appContextState, isLoggedIn: true })
}

//#################################
// GET CORRECT ENVIRONMENT
const host = window.location.host
if (host === 'localhost:3000' || host === 'icondb.lcl') {
  const envUrl = 'https://libraries.lcl'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
} else if (host === 'icon-library.debd.com' || host === 'lufthansa-icons.debd.com' || host === 'ew-discover-icons.debd.com' || host === 'ew-icons.debd.com'  ) {
  const envUrl = 'https://liberries.debd.com'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
} else {
  const envUrl = 'https://liberries.debd.com'
  Object.assign(appContextState, {
    ...appContextState,
    envUrl: `${envUrl}/rest`,
    downloadUrl: `${envUrl}/download`,
  })
}
//#################################

/***********************************
 * REDUCER
 ***********************************/

const reducer = (state: AppConfig = appContextState, action: Action): AppConfig => {
  switch (action.type) {
    case ActionType.SET_SEARCH_FIELD: {
      return {
        ...state,
        searchField: [...state.searchField, action.item],
      }
    }
    case ActionType.DISPLAY_CATEGORY_DOWNLOAD_MODAL: {
      return {
        ...state,
        displayCategoryDownloadModal: action.displayCategoryDownloadModal
      }
    }
    case ActionType.RESET_SEARCH_FIELD_AND_ADD_CATEGORY: {
      return {
        ...state,
        searchField: [action.item],
        searchIsCategory: action.isCategory,
        searchedCategory: action.categoryName,
      }
    }
    case ActionType.RESET_SEARCH_FIELD: {
      return {
        ...state,
        searchField: [],
        searchIsCategory: false,
      }
    }
    case ActionType.DELETE_ITEM_IN_SEARCH_FIELD: {
      const clonedSearchField: SearchField = [...state.searchField]
      const filteredSearchField = clonedSearchField.filter((element, key) => {
        return element !== action.item
      })

      return {
        ...state,
        searchField: filteredSearchField,
        searchedCategory: action.item === state.searchedCategory ? '' : state.searchedCategory,
        searchIsCategory: action.item !== state.searchedCategory
      }
    }
    case ActionType.SET_LOGIN: {
      return {
        ...state,
        isLoggedIn: action.login,
      }
    }
    default: {
      return { ...state }
    }
  }
}
export default reducer
